.App {
  text-align: center;
}

.game-list {
  grid-template-columns: repeat(6, minmax(160px, 1fr));
  /* grid-template-rows: repeat(4, minmax(200px, 1fr));; */
  display: grid;
  row-gap: 1%;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #3f0955;
  background-image: linear-gradient(147deg, #3f0955 0%, #18022b 74%);

}

/* Mobile */

.container {
  font-size: 13.5px;
  margin: auto;
  padding: 5px;
  box-shadow: 10px 5px 5px rgb(30, 3, 61);
  margin: auto;
  background-color: rgb(119, 112, 121);
  color: white;
  border: 2px solid black;
  border-radius: 5px;
  width: 175px;
  height: 230px;
  transition: width .5s, height .5s, font-size .356ms;
}


.container:hover {
   font-size: 13.5px;
   box-shadow: 10px 5px 5px rgb(0, 0, 0);
   height: 235px;
}

/* resize images */
.container img {
  object-fit: contain;
  object-position: top center;
  width: 100%;
  height: 100%;
}

.container .info {
  box-sizing: content-box;
  visibility: hidden;
  opacity: 0;
  width: 175px;
  height: 0;
  line-height: 1.5;
  text-transform: capitalize;
  transition: all ease-in-out 356ms;
  position: absolute;
  color: #fff;
  padding-bottom: 5px;
  font-weight: 500;
  border-top: calc(3px + .03vw) solid #330641;
  border-bottom: calc(3px + .09vw) solid #330641;
  background-color: rgba(0,0,0,0.5);
}

.container:hover .info {
  opacity: 1;
  height: 225px;
  visibility: visible;
}

.App-header {
  font-family: 'Squada One', cursive;
  position: 'relative';
  background-color: #282c34;
  padding: 0px 10px;
  min-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(35px);
  color: white;
  width: 100%;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  border: 1px solid black;
  box-shadow: 0 8px 6px -3px rgb(15, 15, 15);
}

.header-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.header-title p{
  margin: 15px;
}

.header-pages {
  width: 100%;
}

.header-pages ul{
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding:0;
}

.header-pages li{
  font-size: 25px;
  background: transparent; 
  border: none; 
  color: white; 
  text-decoration: none;
  list-style-type: none;
}

.header-pages li a{
  font-size: 25px;
  background: transparent; 
  border: none; 
  color: white; 
  text-decoration: none;
  list-style-type: none;
}

.header-pages li a:hover {
  color: #3f0955
}

.App-header p:hover {
  color: #3f0955
}

  .header-link {
    border: none; 
    background: transparent; 
    text-decoration: none;
    color: white; 
    font-size: 25px; 
     position: absolute;  
     top: 25px;
     right: 17.5px;  
    font-family: 'Squada One';
  }

  .header-link:hover {
    color: #3f0955
  }

  .header-link-left {
    border: none; 
    background: transparent; 
    text-decoration: none;
    color: white; 
    font-size: 25px; 
    /* position: absolute;  */
    /* top: 20px;
    left: 17.5px;  */
    font-family: 'Squada One';
  }

  .header-link-left:hover {
    color: #3f0955
  }

  @media screen and (max-width: 600px) {
    .game-list {
      grid-template-columns: repeat(3, minmax(130px, 1fr));
      display: grid;
      row-gap: 5vh;
    }    
    .container {
      font-size: 7.5px;
      padding: 5px;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 10px 5px 5px rgb(30, 3, 61);
      background-color: rgb(119, 112, 121);
      color: white;
      border: 2px solid black;
      border-radius: 5px;
      width: 105px;
      height: 140px;
      transition: width .5s, height .5s, font-size .356ms;
    }
    .container:hover {
       font-size: 9.5px;
       box-shadow: 10px 5px 5px rgb(0, 0, 0);
       height: 141px;
    }
    
    /* resize images */
    .container img {
      object-fit: contain;
      object-position: top center;
      width: 100%;
      height: 100%;
    }
    
    .container .info {
      box-sizing: content-box;
      visibility: hidden;
      opacity: 0;
      width: 105px;
      height: 0;
      line-height: 1.5;
      text-transform: capitalize;
      transition: all ease-in-out 356ms;
      position: absolute;
      color: #fff;
      padding-bottom: 5px;
      font-weight: 500;
      border-top: calc(3px + .03vw) solid #330641;
      border-bottom: calc(3px + .09vw) solid #330641;
      background-color: rgba(0,0,0,0.5);
    }
    
    .container:hover .info {
      opacity: 1;
      height: 138px;
      visibility: visible;
    }
    
    .App-header {
      font-family: 'Squada One', cursive;
      position: relative;
      background-color: #282c34;
      padding: 0px 10px;
      min-height: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(23px);
      color: white;
      width: 100%;
      text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
      border: 1px solid black;
      box-shadow: 0 8px 6px -3px rgb(15, 15, 15);
    }
    .header-link {
      border: none; 
      background: transparent; 
      text-decoration: none;
      color: white; 
      font-size: 20px;
      position: absolute; 
      right: 17.5px; 
      font-family: 'Squada One';
    }
    .header-link-left {
      border: none; 
      background: transparent; 
      text-decoration: none;
      color: white; 
      font-size: 20px;
      /* font-size: 20px; 
      position: absolute; 
      left: 5.5px;  */
      font-family: 'Squada One';
    }
    .header-pages ul{
      margin: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      padding:0;
    }
    
    .header-pages li{
      font-size: 20px;
      background: transparent; 
      border: none; 
      color: white; 
      text-decoration: none;
      list-style-type: none;
    }
    
    .header-pages li a{
      font-size: 20px;
      background: transparent; 
      border: none; 
      color: white; 
      text-decoration: none;
      list-style-type: none;
    }
    .header-title {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
    }
    
    .header-title p{
      margin: 15px;
    }
    
    .header-pages {
      width: 100%;
    }
  }

/* Tablet */
@media screen and (min-width: 601px) and (max-width: 900px) {

  .game-list {
    grid-template-columns: repeat(3, minmax(160px, 1fr));
    /* grid-template-rows: repeat(4, minmax(200px, 1fr));; */
  }
}

/* Normal */
@media screen and (min-width: 901px) and (max-width: 1200px) {
  .game-list {
    grid-template-columns: repeat(4, minmax(160px, 1fr));
    /* grid-template-rows: repeat(4, minmax(200px, 1fr)); */
  }
}

/* Lg Screen */
@media screen and (min-width: 1201px) {
  .game-list {
    grid-template-columns: repeat(6, minmax(160px, 1fr));
  }
}