.review-button {
    border-radius:25px; 
    background-color:#330641; 
    color:white; 
    height:30px; 
    font-size:20px; 
    position:absolute; 
    top: 5px; 
    right: 10px
  }

.send-button {
    background-color: #4c0066;
    border: none;
    color: white;
    padding: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 4px 2px;
    border-radius: 10%;
    position: absolute;
    bottom: 0.2rem;
    right: 0.5rem;
}

.content-review  {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:#4c0066; 
    border: 5px solid black; 
    margin-left:15%;
    margin-right:15%;
    margin-bottom:15px;
    padding-top: 0px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 5px;
    box-shadow: 10px 5px 5px rgb(30, 3, 61);
  }

.content-detail {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color:#4c0066; 
    border: 5px solid black; 
    /* border-left: 0px;  */
    margin-right: 15.0%;
    margin-bottom: 15px;
    top: 0px;
    padding-top: 0px;
    padding-left: 8px;
    padding-right: 5px;
    padding-bottom: 5px;
    box-shadow: 10px 5px 5px rgb(30, 3, 61);
  }

  .cover-detail {
    display: flex;
    flex-direction: row;
  }

  .img-style {
    min-height:350px; 
    max-height:50vh;
    min-width:200px; 
    max-width:35vw;
    border: 5px solid black; 
    border-right-width: 0px;
    margin-bottom:15px;
    box-shadow: 10px 5px 5px rgb(30, 3, 61);
  }

  .img-container {
    display:flex;
    flex-direction:row; 
    justify-content: center; 
    margin-left: 15%; 
  }



  .overlay {
    position: fixed;
    display: block; 
    overflow: auto; 
    width: 100%; 
    height: 100%; 
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); 
    z-index: 999; 
    cursor: pointer;
  }

.content {
        margin: 15% auto;
        background-color: white;
        border-radius: 0.25rem;
        width: 50vw;
        padding: 2rem;
        position: relative;
  }

.platorm-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 15px;
  color: white;
}

.platorm-list li {
  text-align: left;
}

.platorm-list li span{
  display: block;
}

svg {
  height: 30px;
  cursor: pointer;
}

.carousel {
  text-align: center;
  padding-bottom: 50px;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

  @media screen and (min-width: 300px) and (max-width: 900px) {

    h4 {
      font-size: 13px;
    }

    .cover-detail {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .content-detail {
      margin-right: 5%;
      margin-left: 5%;
      border-left: 5px solid black; 
    }
    .content-review {
      font-size: 13px;
      margin-right: 5%;
      margin-left: 5%;
    }
    .img-container {
      margin-right: 15%;
    }
    .img-style {
      min-height:30vh; 
      max-height:10vh;
      min-width:200px; 
      max-width:25vw;
      border: 5px solid black; 
      border-right-width: 5px;
      margin-bottom:15px;
      box-shadow: 10px 5px 5px rgb(30, 3, 61);
    }
  }